@import "./variables";
@import "~bulmaswatch/flatly/variables";
@import "~bulma/sass/utilities/mixins";

.page {
  padding-top: 10px;
  .hero-body {
    .container {
      display: flex;
      flex-direction: row;

      img {
        height: 50px;
        margin-right: 40px;
      }

      @include mobile {
        flex-direction: column;
      }

      h1,
      h2 {
        margin: 0;
      }
      h1 {
        margin-right: 80px;
      }
    }
  }
  & > .container {
    margin-top: $margin-header;
    margin-bottom: $margin-footer;
    padding-left: 20px;
    padding-right: 20px;
    @include from($desktop) {
      padding-left: 0;
      padding-right: 0;
    }

    margin-left: 33px !important;
    margin-right: 33px !important;
    max-width: 100% !important;
  }
}
