.page-content {
  margin-top: 60px;
}

.constuction {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


ul li {
  list-style-type: disc !important;
  margin-left: 25px;
  width: fit-content;
}
ol li {
  list-style-type: decimal !important;
  margin-left: 25px;
  width: fit-content;
}
h1 {
  font-size: 2em !important;
  font-weight: bolder;
}
h2 {
  font-size: 1.5em !important;
  font-weight: bolder;
}
h3 {
  font-size: 1.17em !important;
  font-weight: bold;
}
h4 {
  font-size: 1em !important;
  font-weight: bold;
}
h5 {
  font-size: .83em !important;
}
h6 {
  font-size: .67em !important;
}
