@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
@import "~bulmaswatch/flatly/variables";
@import "./variables";

$navbar-background-color: #34495e;
$navbar-item-color: #fff;
$navbar-radius: 0px;
$menu-label-color: #34495e;
$menu-item-color: #34495e;
$box-background-color: $white;

h1 {
  font-size: 24px !important;
}

p,
li {
  color: #34495e;
}

.navbar {
  border-radius: 0 !important;
}

header {
  background-color: #34495e;
}

@import "~bulma";
@import "~bulmaswatch/flatly/overrides";

.navbar .navbar-menu {
  border-radius: 0;
}

@mixin box-shadow {
  transition: all $animation-speed;
  &.with-shadow {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  &.with-shadow.has-hover:hover {
    transform: translateY(-1px);
  }
}

.card {
  border: none;
  overflow: hidden;
  @include box-shadow;
}

.button {
  @include box-shadow;
}

.box {
  background: $white-bis;
  @include box-shadow;
}

.footer {
  background: $white-bis;
}

.hero .hero-body {
  background: $white-bis;
}

aside {
  .menu-label {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 5px !important;
    margin-top: 10px !important;
  }
}