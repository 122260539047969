@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "../variables";

.employee {
  img {
    border: 1px solid transparent;
    transition: border $animation-speed;
    height: 60px;
    width: auto;
    margin: 0 auto;
  }
  &.is-selected img {
    border: 1px solid $primary;
  }
}
