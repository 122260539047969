.rent,
.rent-overview {
  max-width: 1000px;
  margin: 0 auto;
}

.rent {
  .rent-content {
    &.start {
      ul {
        li {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    &.services {
      ul {
        margin-bottom: 10px;
        li {
          .up{
            display: flex;
            align-items: center;

            input[type="checkbox"] {
              margin-right: 10px;
            }
          }
        }
      }

      input[type="number"],
      select {
        max-width: 100%;
        width: 200px;
      }
    }

    &.checkout {
      h4 {
        margin-top: 0px;
        margin-bottom: 15px;
      }
    }

    button {
      margin-top: 20px;
      float: right;
    }
  }
}
.rent-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-bottom: 40px;

  h3 {
    margin-bottom: 20px;
  }

  a {
    display: block;
    position: absolute;
    bottom: 0px;
  }
}
