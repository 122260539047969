.landing-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  padding: 4% 0;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
}

.landing-container .box {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
  height: 100%;
  padding: 15px;
}

#video_background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1000;
}

.landing-container .middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.landing-container .top {
  max-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.landing-container .bottom {
  max-height: 250px;
  width: 100%;
}

.landing-container .right {
  opacity: 0;
}

.logo-landing {
  background-color: white;
  height: 60px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  right: 20px;
  top: 20px;

  @media screen and (max-width: 500px) {
    top: 20px;
    left: 20px;
  }
}
