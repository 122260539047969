.slideshow{
  position: relative;

  max-height: 300px;

  img{
    max-height: 300px;
    width: auto;
    margin: 0 auto;
  }

  img.arrow{
    position: absolute;
    width: 25px;
    height: 25px;

    top: 45%;

    &.left{
      left: 20px;
    }

    &.right{
      right: 20px;
    }
  }
}
