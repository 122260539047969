@import "~bulma/sass/utilities/mixins";

header {
  .cart {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    margin-left: auto;
    padding: 20px;

    svg:hover {
      color: #1abc9c;
    }
  }

  .navbar-burger {
    margin-left: 0px;
  }

  .navbar {
    height: 70px;
    display: flex;
    align-items: center;
    .navbar-brand {
      margin-right: 30px;

      a {
        margin-top: 5px;
        img {
          background-color: white;
          height: 60px;
          padding: 5px 10px;
          border-radius: 10px;
        }
      }
    }
    .navbar-menu {
      .navbar-item {
        font-weight: 400;
        margin: 10px 10px;
        font-size: 18px;

        &.is-active {
          color: white;
          border-bottom: 3px solid white;
        }
      }
      .slogan{
        display: block;
        text-align: center;
        margin: auto;
        margin-left: 20px;
        font-weight: 500;
        color: white;
      }
    }
  }

  @include desktop {
    .navbar-brand .navbar-item {
      padding-left: 0;
    }
    .navbar {
      padding: 0px 30px;
    }
    .cart-mobile {
      display: none;
    }
  }

  @include touch {
    .cart-desktop {
      display: none;
    }
  }
}
