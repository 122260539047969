.landing {
  padding: 20px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer-construction {
  bottom: 0;
  position: absolute;
  width: 100%;
}
