@import "./variables";
@import "~bulma/sass/utilities/initial-variables";

.products,
.exercises,
.articles,
.calendar,
.socials {
  margin-bottom: 40px;
}
a.img-has-overlay {
  position: relative;
  display: block;
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 10px;
  }
}

.home {
  margin-top: $margin-header;
  margin-bottom: $margin-footer;

  hr {
    background-color: lightgray;
  }

  .hero-body {
    padding: 0;
    padding-top: 20px;
    margin-bottom: 30px;
    .container {
      padding: 0;
      padding: 4px;
      height: 100px;
      display: flex;
      justify-content: space-between;

      .clients {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-around;
        width: auto;

        .level {
          width: 100%;

          .level-item {
            img {
              height: 30px;
            }
            .heading {
              margin-top: 5px;
            }
          }
        }
      }
      .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
      }
    }
  }

  .client-wrapper {
    background-color: #FAFAFA;

    .client {
      height: 120px;
      margin-bottom: 20px;
      padding-top: 36px;

      .client-slider, .slick-slider {
        width: calc(100% - 186px);
      }

      .contact {
        position: absolute;
        right: 0;
        margin-top: -61px;
        background-color: #FAFAFA;
      }

      .client-div {
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        img {
          height: 30px;
        }
        .heading {
          margin-top: 5px;
        }
      }
    }
  }

  .product,
  .exercises,
  .articles {
    .card {
      .card-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
      }
    }
  }

  .partner{
    p{
      max-width: 450px;
      margin: 0 auto 20px auto;
    }
  }

  .card-image img {
    border-radius: 0;
  }

  .calendar {
    .control,
    .select,
    select {
      width: 100%;
    }
    a {
      margin-top: 10px;
      font-weight: 400;
      width: 100%;
    }
  }

  .socials {
    iframe {
      min-height: 500px;
    }
  }
}
