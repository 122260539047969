@import "~bulmaswatch/flatly/variables";
@import "~bulma/sass/utilities/mixins";

.products {
  aside{
    overflow-y: scroll;
    height: 600px;
  }
  .product {
    .parts {
      max-height: 400px;
      overflow-y: scroll;
    }

    .product-title {
      @include mobile {
        //flex-direction: column;
      }
    }
  }
}
