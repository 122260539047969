.exercises {
  aside {
    margin-top: 20px;
  }
  img {
    margin-bottom: 20px;
  }

  .more{
    margin-top: 40px;
    font-weight: 500;
  }

  iframe{
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}
