.cookie {
  cursor: pointer;
  margin-left: 15px;
}

.cookie:hover {
  text-decoration: underline;
}

.cookie-box {
  display: flex;
}

.cookie-container:not(:first-child) {
  margin-top: 10px;
}
