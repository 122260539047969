.checkout {
  .modal {
    .modal-content {
      background-color: white;
      border-radius: 6px;
      padding: 40px;

      .content {
        margin: 40px 0px;
        .cart-header {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          color: #363636;
          font-weight: bold;
          margin-right: 60px;
          margin-bottom: 10px;

          span {
            margin-right: 30px;
          }
        }
        .cart-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;

          img {
            width: 50px;
            height: auto;
          }

          .name {
            flex: 1;
            margin-left: 20px;
            color: #363636;
          }

          .input {
            width: 60px;
            margin-right: 20px;
          }

          .price {
            margin-right: 20px;
            width: 60px;
            color: #363636;
            font-weight: 500;
          }

          .remove {
          }
        }

        .field-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .field {
            flex: 1;
          }

          .field:not(:first-child) {
            margin-left: 20px;
          }
        }

        form {
          .radio {
            margin-right: 20px;
            color: #363636;
            font-weight: 400;

            input[type="radio"] {
              margin-right: 10px;
            }
          }
        }
      }

      .bottom {
        button {
          margin-left: 20px;
          margin-right: 0;
          float: right;
        }
      }
    }
  }
}
